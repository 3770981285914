$primary: #3e00da; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

.flash {
	display:none;
}

nav {
	z-index: 1000;
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 80px;
		display: flex;
		align-items: center;

		@media (max-width: 767px) {
		    height: 45px;
		    display: inline-block;
		}

	    &:hover, &:focus {
          background: transparent;
          color: $primary;
	    }
	}
}

.navbar-right {
	margin-top: 0px;
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 767px) {
		margin-top: 22px;
    margin-right: 35px;
	}
}

.logo {
  max-width: 175px;
  margin: 0.5em 1em;
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}





/** UNTITLED CSS **/
i, i.fa {
  font-size: 70px;
  color: $primary;
}

.row {
  padding:30px 0px;
}

.primaryBg {
  background:$primary;
  color:white;
}

h1, h2, h3, h4 {
  color:$primary;
}

.primaryBg h1, .primaryBg h2, .primaryBg h3, .primaryBg h4 {
  color:white;
}

hr {
  width:150px;
  border: none;
  border-bottom: 2px dashed $primary;
}

body {
  font-size:17px;
}

a.btn.btn-primary.btn-lg {
  background:$primary;
  border-radius:25px;
  padding:0.75em 1.5em;
  text-transform:uppercase;
  letter-spacing:1px;
}

.noPad {
  padding-left:0px;
  padding-right:0px;
}

.zeroPad {
  padding: 0px;
}




/** CAROUSEL-HERO **/
div.jumbotron.carousel-hero {
  color:#fff;
  text-align:center;
  background-size:cover;
  background-position:center;
  padding-top:100px;
  padding-bottom:100px;
  margin-bottom:0;
}

div.jumbotron.hero-1 {
  background-image:url('../img/slider1.jpg');
    @media (max-width: 767px) {
      background-position: 25% 0% !important;
    }  
}

div.jumbotron.hero-2 {
  background-image:url('../img/slider2.jpg');
    @media (max-width: 767px) {
      background-position: 42% 0% !important;
    }  
}

div.jumbotron.hero-3 {
  background-image:url('../img/slider3.jpg');
    @media (max-width: 767px) {
      background-position: 59% 0% !important;
    }    
}

h1.hero-title, h2.hero-title, h3.hero-title {
  text-shadow:0 3px 3px rgba(0,0,0,0.4);
  color:#fff;
}

p.hero-subtitle {
  max-width:600px;
  margin:25px auto;
  padding:10px 15px;
  color:#fff;
}

/** SIMPLE-SLIDER CSS **/
.simple-slider .swiper-slide {
  height:750px;
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

#slide1 {
  background: url(../img/slide1.jpg) no-repeat center;
  background-size: cover;
    @media (max-width: 1100px) {
      background-position: left !important;
    }
}

#slide2 {
  background: url(../img/slide2.jpg) no-repeat center;
  background-size: cover;

    @media (max-width: 1100px) {
      background-position: -8em 0em !important;
    }  
  
    @media (max-width: 767px) {
      background-position: left !important;
    }

    @media (max-width: 400px) {
      background-position: 25% 0% !important;
    }    
}


#slide3 {
  background: url(../img/slide3.jpg) no-repeat 0em 4em;
  background-size: cover;

    @media (max-width: 1100px) {
      background-position: -15em 1.5em !important;
    }  
    @media (max-width: 767px) {
      background-position: -8.5em 0.8em !important;
    }  
}

.simple-slider{
  @media (max-width: 991px) {
    padding-top: 3em;
  }
 
  h1 {
    color: rgba(255,255,255,0.9);
    filter: drop-shadow(0px 5px 5px rgba(62,0,218,0.9));
    font-size: 8em;
    text-transform: uppercase;
    font-family: 'Bahiana', sans-serif;

    @media (max-width: 991px) {
      font-size: 4em;
      padding: 0em 2em;
    }

    @media (max-width: 767px) {
      font-size: 3em;
      padding: 0em 1em;
    }
  }
} 

.simple-slider .swiper-button-next, .simple-slider .swiper-button-prev {
  width:50px;
  margin-left:20px;
  margin-right:20px;
}

@media (max-width:767px) {
  .simple-slider .swiper-button-next, .simple-slider .swiper-button-prev {
    display:none;
  }
}

@media (max-width:767px) {
  .simple-slider .swiper-slide {
    height:360px;
  }
}



/** REGISTRATION **/
.register-photo {
  background:#f1f7fc;
  padding:80px 0;
}

.register-photo .image-holder {
  background: url(../img/couple.png) no-repeat center;
  background-size:cover;
  padding: 17em 0em;

  @media (max-width: 767px) {
    padding: 11em 0em;
  }
}

.register-photo .form-container {
  display:table;
  max-width: 75%;
  width:100%;
  margin:0 auto;
  box-shadow:1px 1px 5px rgba(0,0,5,0.5);
}

.register-photo form {
  display:table-cell;
  width:400px;
  background-color:#ffffff;
  padding:40px 60px;
  color:#505e6c;
}

@media (max-width:991px) {
  .register-photo form {
    padding:40px;
  }
}

.register-photo form h2 {
  font-size:24px;
  line-height:1.5;
  margin-bottom:30px;
}

.register-photo form .form-control {
  background:#f7f9fc;
  border:none;
  border-bottom:1px solid #dfe7f1;
  border-radius:0;
  box-shadow:none;
  outline:none;
  color:inherit;
  text-indent:6px;
  height:40px;
}

.register-photo form .checkbox {
  font-size:13px;
  line-height:20px;
}

.register-photo form .btn-primary {
  background:#f4476b;
  border:none;
  border-radius:4px;
  padding:11px;
  box-shadow:none;
  margin-top:35px;
  text-shadow:none;
  outline:none !important;
}

.register-photo form .btn-primary:hover, .register-photo form .btn-primary:active {
  background:#eb3b60;
}

.register-photo form .btn-primary:active {
  transform:translateY(1px);
}

.register-photo form .already {
  display:block;
  text-align:center;
  font-size:12px;
  color:#6f7a85;
  opacity:0.9;
  text-decoration:none;
}
.top-pad {
  padding-top: 3em;
}


@media(max-width: 767px) {
  .register-photo .form-container {
    max-width: 95%;
  }

  .is-sticky {
    height: 78px !important;
  }
}